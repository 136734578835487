.about__backcloud {
  position: absolute;
  right: 650px;
  top: 1300px;
  animation-name: floating;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  position: left;
  z-index: -1;
}

.about__background {
  height: auto;
}

.about__background h2 {
    padding: 2rem;
}

@keyframes about__backcloud {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 15px);
  }
  100% {
    transform: translate(0, -0px);
  }
}

.about__container {
  display: grid;
  grid-template-rows: 40% 50%;
  gap: 5%;
  position: relative;
  padding: 0rem 2rem;
  justify-items: center;
  align-items: center;
  grid-auto-rows: auto;
}


.about__cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
}



.about__icon {
  color: var(--color-primary);
  font-size: 1.4rem;
  margin-bottom: 1rem;
}

.about__card h5 {
  font-size: 0.95rem;
}

.about__card small {
  font-size: 0.7rem;
  color: var(--color-black);
}

.about__content {
   background-color: rgba(52, 48, 50, 0.2);
   border-radius: 2rem;
    padding: 2rem;
}

.about__content p {
  font-size: 25px;
}

.about__content h1 {
  color: white;
}


@media (max-width: 500px) {
  .social-image {
    padding: 0px;
    margin: 0px;
    width: 350px;
    position: relative;
    right: 40px;
    justify-content: center;
    margin-bottom: 70px
  }

  .blog-title {

    width: 200px;
  }
}