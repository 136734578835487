.BlogPosts {





}
.carousel {
    background: var(--color-primary);
    padding: 3rem;
    border-radius: 2rem;
    border: 1px solid transparent;
  
}

.social-image {
    padding: 0px 70px 150px 70px
}